<template>
  <div class="yl-map">
    <Row class="content">
      <Col :span="24">
        <div class="map">
          <baidu-map class="model" :center="map.center" :zoom="map.zoom" :mapStyle="mapStyle" @ready="handlerMap">
            <!-- 坐标点   -->
            <bm-marker
              v-for="(item, index) in oldData"
              :key="index"
              :dragging="true"
              :position="{ lng: item.LONGITUDE, lat: item.LATITUDE }"
              @mouseover="lookDetail(item)"
              @mouseout="closeDetail(item)"
            >
              <!--  @click="lookDetail(item)" -->
              <!-- 坐标点信息提示窗口 -->
              <bm-info-window
                ref="infoWindow"
                :show="item.makerFlag"
                :position="{ lng: infoWindow.LONGITUDE, lat: infoWindow.LATITUDE }"
                @close="infoWindowClose(item)"
                @open="infoWindowOpen(item)"
              >
                <div class="box">
                  <div class="title">姓名：{{ infoWindow.name }}</div>
                  <div class="phone">电话：{{ infoWindow.phone }}</div>
                  <div class="age">年龄：{{ infoWindow.age }}</div>
                  <div class="gender">性别：{{ infoWindow.gender }}</div>
                  <div class="state">现状：{{ infoWindow.state }}</div>
                  <div class="medicalHis">病史：{{ infoWindow.medicalHis }}</div>
                  <div class="address">地址：{{ infoWindow.address }}</div>
                </div>
                <div class="btn" @click="jumpDetail(item)">查看详情</div>
              </bm-info-window>
            </bm-marker>
          </baidu-map>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import { getNodecl } from '@/api/home'

export default {
  name: 'page6',
  data() {
    return {
      infoWindow: {},
      map: {
        center: { lng: 119.611633, lat: 39.95869 }, // 秦皇岛经纬度
        zoom: 15,
        show: true,
        dragging: true,
        radius: 3000,
        MapType: 'BMAP_SATELLITE_MAP'
      },
      mapStyle: {
        styleJson: [
          {
            featureType: 'land',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#091220ff'
            }
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#113549ff'
            }
          },
          {
            featureType: 'green',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#0e1b30ff'
            }
          },
          {
            featureType: 'building',
            elementType: 'geometry',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'building',
            elementType: 'geometry.topfill',
            stylers: {
              color: '#113549ff'
            }
          },
          {
            featureType: 'building',
            elementType: 'geometry.sidefill',
            stylers: {
              color: '#143e56ff'
            }
          },
          {
            featureType: 'building',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#dadada00'
            }
          },
          {
            featureType: 'subwaystation',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#113549B2'
            }
          },
          {
            featureType: 'education',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#12223dff'
            }
          },
          {
            featureType: 'medical',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#12223dff'
            }
          },
          {
            featureType: 'scenicspots',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#12223dff'
            }
          },
          {
            featureType: 'highway',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              weight: 4
            }
          },
          {
            featureType: 'highway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'highway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#fed66900'
            }
          },
          {
            featureType: 'highway',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'highway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'highway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'highway',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'arterial',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              weight: 2
            }
          },
          {
            featureType: 'arterial',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'arterial',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffeebb00'
            }
          },
          {
            featureType: 'arterial',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'arterial',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'arterial',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'local',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              weight: 1
            }
          },
          {
            featureType: 'local',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'local',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'local',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'local',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#979c9aff'
            }
          },
          {
            featureType: 'local',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffffff'
            }
          },
          {
            featureType: 'railway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'subway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              weight: 1
            }
          },
          {
            featureType: 'subway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#d8d8d8ff'
            }
          },
          {
            featureType: 'subway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'subway',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'subway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#979c9aff'
            }
          },
          {
            featureType: 'subway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffffff'
            }
          },
          {
            featureType: 'continent',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'continent',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'continent',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'continent',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'city',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'city',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'city',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'city',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'town',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'town',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'town',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#454d50ff'
            }
          },
          {
            featureType: 'town',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffffff'
            }
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'poilabel',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'districtlabel',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'district',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'poilabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'poilabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'poilabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'manmade',
            elementType: 'geometry',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'districtlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffffff'
            }
          },
          {
            featureType: 'entertainment',
            elementType: 'geometry',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'shopping',
            elementType: 'geometry',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'nationalway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '6'
            }
          },
          {
            featureType: 'nationalway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '7'
            }
          },
          {
            featureType: 'nationalway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '8'
            }
          },
          {
            featureType: 'nationalway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '9'
            }
          },
          {
            featureType: 'nationalway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '10'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '6'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '7'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '8'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '9'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '10'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '6'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '7'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '8'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '9'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '10'
            }
          },
          {
            featureType: 'cityhighway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '6'
            }
          },
          {
            featureType: 'cityhighway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '7'
            }
          },
          {
            featureType: 'cityhighway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '8'
            }
          },
          {
            featureType: 'cityhighway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '9'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '6'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '7'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '8'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '9'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '6'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '7'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '8'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '9'
            }
          },
          {
            featureType: 'subwaylabel',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'subwaylabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'tertiarywaysign',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'tertiarywaysign',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'provincialwaysign',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'provincialwaysign',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'nationalwaysign',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'nationalwaysign',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'highwaysign',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'highwaysign',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'village',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'district',
            elementType: 'labels.text',
            stylers: {
              fontsize: 20
            }
          },
          {
            featureType: 'district',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'district',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'country',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'country',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'tertiaryway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'tertiaryway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff10'
            }
          },
          {
            featureType: 'provincialway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'provincialway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'highway',
            elementType: 'labels.text',
            stylers: {
              fontsize: 20
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels.text',
            stylers: {
              fontsize: 20
            }
          },
          {
            featureType: 'provincialway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'provincialway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'provincialway',
            elementType: 'labels.text',
            stylers: {
              fontsize: 20
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels.text',
            stylers: {
              fontsize: 20
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'estate',
            elementType: 'geometry',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'tertiaryway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'tertiaryway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'fourlevelway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'fourlevelway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'scenicspotsway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'scenicspotsway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'universityway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'universityway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'vacationway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'vacationway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'fourlevelway',
            elementType: 'geometry',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'fourlevelway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'fourlevelway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'transportationlabel',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'transportationlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'transportationlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'transportationlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'educationlabel',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'educationlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'educationlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'educationlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'transportation',
            elementType: 'geometry',
            stylers: {
              color: '#113549ff'
            }
          },
          {
            featureType: 'airportlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'airportlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'scenicspotslabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'scenicspotslabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'medicallabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'medicallabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'medicallabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'scenicspotslabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'airportlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'entertainmentlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'entertainmentlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'entertainmentlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'estatelabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'estatelabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'estatelabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'businesstowerlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'businesstowerlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'businesstowerlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'companylabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'companylabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'companylabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'governmentlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'governmentlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'governmentlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'restaurantlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'restaurantlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'restaurantlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'hotellabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'hotellabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'hotellabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'shoppinglabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'shoppinglabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'shoppinglabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'lifeservicelabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'lifeservicelabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'lifeservicelabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'carservicelabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'carservicelabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'carservicelabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'financelabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'financelabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'financelabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'otherlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'otherlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'otherlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'manmade',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'manmade',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'transportation',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'transportation',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'education',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'education',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'medical',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'medical',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'scenicspots',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'scenicspots',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          }
        ]
      },
      list: [
        {
          makerFlag: false,
          LONGITUDE: 119.606127,
          LATITUDE: 39.954853,
          COMPNAME: '海港区',
          ADDRESS: '',
          PHONE: '',
          pepleData: [
            {
              name: '王小利',
              phone: '13332568975',
              age: '68',
              gender: '男',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市海港区矿院里小区3栋1单元101',
              LONGITUDE: 119.599552,
              LATITUDE: 39.952018
            },
            {
              name: '李利',
              phone: '13835365123',
              age: '69',
              gender: '男',
              state: '独居',
              medicalHis: '心脏病',
              address: '秦皇岛市海港区幸福里小区8栋1单元102',
              LONGITUDE: 119.617194,
              LATITUDE: 39.939323
            },
            {
              name: '冯小敏',
              phone: '18611563266',
              age: '66',
              gender: '女',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市海港区铁路里小区2栋2单元101',
              LONGITUDE: 119.60681,
              LATITUDE: 39.931951
            },
            {
              name: '李丽',
              phone: '13945668975',
              age: '70',
              gender: '男女',
              state: '独居',
              medicalHis: '糖尿病',
              address: '秦皇岛市海港区和安里小区19栋1单元201',
              LONGITUDE: 119.581648,
              LATITUDE: 39.943856
            },
            {
              name: '沈春丽',
              phone: '18821333265',
              age: '70',
              gender: '女',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市海港区新闻北里小区13栋1单元101',
              LONGITUDE: 119.597488,
              LATITUDE: 39.951769
            }
          ]
        },
        {
          makerFlag: false,
          LONGITUDE: 119.490596,
          LATITUDE: 39.841227,
          COMPNAME: '北戴河区',
          ADDRESS: '',
          PHONE: '',
          pepleData: [
            {
              name: '王小利',
              phone: '13332568975',
              age: '68',
              gender: '男',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市北戴河区北岭一区38栋1单元101',
              LONGITUDE: 119.493277,
              LATITUDE: 39.834623
            },
            {
              name: '李利',
              phone: '13835365123',
              age: '69',
              gender: '男',
              state: '独居',
              medicalHis: '心脏病',
              address: '秦皇岛市北戴河区南岭小区2栋1单元102',
              LONGITUDE: 119.49153,
              LATITUDE: 39.830205
            },
            {
              name: '冯小敏',
              phone: '18611563266',
              age: '66',
              gender: '女',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市北戴河区育花一区3栋2单元101',
              LONGITUDE: 119.514666,
              LATITUDE: 39.835385
            }
          ]
        },
        {
          makerFlag: false,
          LONGITUDE: 119.783731,
          LATITUDE: 39.991357,
          COMPNAME: '山海关区',
          ADDRESS: '',
          PHONE: '',
          pepleData: [
            {
              name: '王小利',
              phone: '13332568975',
              age: '68',
              gender: '男',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市山海关区金水家园5栋1单元101',
              LONGITUDE: 119.791043,
              LATITUDE: 40.006557
            },
            {
              name: '李利',
              phone: '13835365123',
              age: '69',
              gender: '男',
              state: '独居',
              medicalHis: '心脏病',
              address: '秦皇岛市山海关区三角地小区55栋1单元102',
              LONGITUDE: 119.772919,
              LATITUDE: 39.997037
            },
            {
              name: '冯小敏',
              phone: '18611563266',
              age: '66',
              gender: '女',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市山海关区南海花园小区6栋2单元101',
              LONGITUDE: 119.785321,
              LATITUDE: 39.9903
            }
          ]
        },
        {
          makerFlag: false,
          LONGITUDE: 119.252985,
          LATITUDE: 39.881578,
          COMPNAME: '抚宁区',
          ADDRESS: '',
          PHONE: '',
          pepleData: [
            {
              name: '王小利',
              phone: '13332568975',
              age: '68',
              gender: '男',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市抚宁区骊城龙园6栋1单元101',
              LONGITUDE: 119.250448,
              LATITUDE: 39.889603
            },
            {
              name: '李利',
              phone: '13835365123',
              age: '69',
              gender: '男',
              state: '独居',
              medicalHis: '心脏病',
              address: '秦皇岛市抚宁区金山小区22栋1单元102',
              LONGITUDE: 119.247573,
              LATITUDE: 39.888783
            },
            {
              name: '冯小敏',
              phone: '18611563266',
              age: '66',
              gender: '女',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市抚宁区交通小区3栋2单元101',
              LONGITUDE: 119.256017,
              LATITUDE: 39.887305
            }
          ]
        },
        {
          makerFlag: false,
          LONGITUDE: 119.177347,
          LATITUDE: 39.710536,
          COMPNAME: '昌黎县',
          ADDRESS: '',
          PHONE: '',
          pepleData: [
            {
              name: '王小利',
              phone: '13332568975',
              age: '68',
              gender: '男',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市昌黎县滨海国际1栋1单元101',
              LONGITUDE: 119.176283,
              LATITUDE: 39.719513
            },
            {
              name: '李利',
              phone: '13835365123',
              age: '69',
              gender: '男',
              state: '独居',
              medicalHis: '心脏病',
              address: '秦皇岛市昌黎县丽京花园13栋1单元102',
              LONGITUDE: 119.170466,
              LATITUDE: 39.728476
            },
            {
              name: '冯小敏',
              phone: '18611563266',
              age: '66',
              gender: '女',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市昌黎县泰安盛世40栋2单元101',
              LONGITUDE: 119.174697,
              LATITUDE: 39.731715
            }
          ]
        },
        {
          makerFlag: false,
          LONGITUDE: 118.898379,
          LATITUDE: 39.898664,
          COMPNAME: '卢龙县',
          ADDRESS: '',
          PHONE: '',
          pepleData: [
            {
              name: '王小利',
              phone: '13332568975',
              age: '68',
              gender: '男',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市卢龙县龙泽城21栋1单元101',
              LONGITUDE: 118.904101,
              LATITUDE: 39.89793
            },
            {
              name: '李利',
              phone: '13835365123',
              age: '69',
              gender: '男',
              state: '独居',
              medicalHis: '心脏病',
              address: '秦皇岛市卢龙县东方之珠小区1栋1单元102',
              LONGITUDE: 118.899547,
              LATITUDE: 39.901279
            },
            {
              name: '冯小敏',
              phone: '18611563266',
              age: '66',
              gender: '女',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市卢龙县蓝钻公馆5栋2单元101',
              LONGITUDE: 118.895154,
              LATITUDE: 39.904088
            }
          ]
        },

        {
          makerFlag: false,
          LONGITUDE: 118.955259,
          LATITUDE: 40.409278,
          COMPNAME: '青龙满族自治县',
          ADDRESS: '',
          PHONE: '',
          pepleData: [
            {
              name: '王小利',
              phone: '13332568975',
              age: '68',
              gender: '男',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市青龙满族自治县锦绣家园3栋1单元101',
              LONGITUDE: 118.954972,
              LATITUDE: 40.420306
            },
            {
              name: '李利',
              phone: '13835365123',
              age: '69',
              gender: '男',
              state: '独居',
              medicalHis: '心脏病',
              address: '秦皇岛市青龙满族自治县裕祥园5栋1单元102',
              LONGITUDE: 118.946806,
              LATITUDE: 40.413481
            },
            {
              name: '冯小敏',
              phone: '18611563266',
              age: '66',
              gender: '女',
              state: '独居',
              medicalHis: '无病史',
              address: '秦皇岛市青龙满族自治县龙城明珠5栋2单元101',
              LONGITUDE: 118.938708,
              LATITUDE: 40.412447
            }
          ]
        }
      ],
      oldData: []
    }
  },
  created() {
    this.getNodecl()
    this.getDistrictMap()
  },
  methods: {
    getDistrictMap() {
      const district = this.$route.params.district
      console.log(district)
      const res = this.list.filter((item) => item.COMPNAME === district)
      this.map.center.lat = res[0].LATITUDE
      this.map.center.lng = res[0].LONGITUDE
      this.oldData = res[0].pepleData
    },
    getNodecl() {
      getNodecl().then((res) => {
        this.list = res.data
      })
    },
    lookDetail(marker) {
      console.log('marker', marker)

      marker.makerFlag = true
      this.infoWindow = marker
    },
    closeDetail(marker) {
      marker.makerFlag = false
    },
    infoWindowClose(marker) {
      marker.makerFlag = false
    },
    infoWindowOpen(marker) {
      marker.makerFlag = true
    },
    jumpDetail(item) {
      this.$router.push({ name: 'ylDetail', params: { content: item } })
    },
    handlerMap({ map }) {
      // this.BMap = map

      // 鼠标缩放
      map.enableScrollWheelZoom(true)

      // 圆形覆盖物相关逻辑
      // const radius = this.map.radius
      // for (const item of this.list) {
      //   const circle = new this.$baidu.BMap.Circle(new this.$baidu.BMap.Point(item.LONGITUDE, item.LATITUDE), radius, {
      //     strokeColor: 'blue',
      //     fillOpacity: 0.3,
      //     strokeWeight: 1,
      //     strokeOpacity: 0.5
      //   })
      //   circle.setRadius(radius, true)
      //   map.addOverlay(circle)

      //   const polyline = new this.$baidu.BMap.Polyline(
      //     [new this.$baidu.BMap.Point(item.LONGITUDE, item.LATITUDE), new this.$baidu.BMap.Point(item.LONGITUDE, item.LATITUDE)],
      //     {
      //       strokeColor: 'red',
      //       strokeStyle: 'dashed',
      //       strokeWeight: 2,
      //       strokeOpacity: 0.5
      //     }
      //   )
      //   map.addOverlay(polyline)
      // }
      // 设置圆形覆盖物可编辑
      // circle.enableEditing()
      // 点击事件获取经纬度
      // map.addEventListener('click', function (e) {
      //   /*me.map.show = true*/
      //   console.log(e)
      //   map.openInfoWindow()
      // })
    }
  }
}
</script>

<style lang="less" scoped>
.yl-map {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .content {
    height: 100%;
    position: relative;
    .map {
      height: 100%;
      position: relative;
      .model {
        height: 100%;
        position: relative;

        .box {
          height: 100%;
          margin-top: 20px;
          font-size: 10px;

          .title {
            width: 100%;
            height: 40px;
            font-size: 12px;
            font-weight: 700;
            color: #cc5522;
            text-align: left;
            background-color: transparent;
          }
          .address {
            text-wrap: nowrap;
          }
        }
        .btn {
          font-size: 12px;
          // font-weight: 700;
          color: #cc5522;
          text-align: center;
          cursor: pointer;
          height: 25px;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
